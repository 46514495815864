import { defineComponent } from 'vue';
import type { AxiosError } from 'axios';
import type { RouteParamsRaw } from 'vue-router';

// TODO: This has been converted to a composable, but this is still used at the global level.  Need
//  to clean up after Vue 3 migration.

export interface ErrorScreenProps extends RouteParamsRaw {
  icon: string;
  title: string;
  message: string;
  backEnabled?: string; // See it as a boolean. That's a type limitation, 'cause it has to be number or string.
}

export default defineComponent({
  methods: {
    makeErrorScreenParams(error: AxiosError): ErrorScreenProps {
      if (error.response?.data) {
        let { message } = error.response?.data as any;
        message = Array.isArray(message) ? message[0] : message;

        if (
          /^You must be within a [A-Za-z0-9 ]+ radius to check in to your appointment$/gu.test(
            message
          )
        ) {
          return {
            icon: 'mdi-map-marker-off-outline',
            title: 'We could not validate your location',
            backEnabled: '1',
            message
          };
        }

        if (
          /^You must be within \d+ hours?( and \d+ minutes?)? (before, and \d+ hours?( and \d+ minutes?)? after|after, and \d+ hours?( and \d+ minutes?)? before) your appointment to be able to check-in$/gu.test(
            message
          )
        ) {
          return {
            icon: 'mdi-clock-alert-outline',
            title: 'Not on time to check-in',
            backEnabled: '1',
            message
          };
        }

        if (/^(Warehouse|Org) is not configured for checking in$/gu.test(message)) {
          return {
            icon: 'mdi-alert-circle',
            title: 'Warehouse not available',
            backEnabled: '0',
            message: 'Please contact the facility personnel to proceed with manual check-in'
          };
        }

        if (/^You can't check in to an appointment marked as [A-Za-z]+$/gu.test(message)) {
          return {
            icon: 'mdi-calendar-remove',
            title: message,
            backEnabled: '1',
            message: ''
          };
        }

        if (
          /^You can't check in to an appointment already associated with an asset visit$/gu.test(
            message
          )
        ) {
          return {
            icon: 'mdi-alert-circle',
            title: message,
            backEnabled: '1',
            message: ''
          };
        }
      }

      return {
        icon: 'mdi-map-marker-off-outline',
        title: 'We could not locate your appointment',
        backEnabled: '1',
        message: 'Please confirm the information with your dispatcher or reach out to the warehouse'
      };
    }
  }
});
