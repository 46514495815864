/* eslint-disable no-undefined */

/**
 * Safely converts an object to a JSON string, handling circular references.
 *
 * This function serializes an object to a JSON string while detecting and
 * handling circular references to prevent errors. Circular references are
 * replaced with `undefined` remove them from the resulting JSON string.
 *
 * @param obj - The object to be serialized.
 * @param indent - Optional number of spaces to use for indentation in the resulting JSON string.
 * @returns {string} The JSON string representation of the object.
 */
export function safeStringify(obj: any, indent?: number): string {
  const seen = new WeakSet();
  const replacer = (_: string, value: any) => {
    if (seen.has(value)) {
      return undefined;
    }
    if (typeof value === 'object' && value !== null) {
      seen.add(value);
    }
    return value;
  };

  return JSON.stringify(obj, replacer, indent);
}
