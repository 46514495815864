const RenderModeEnum = Object.freeze({
  DESKTOP: 'desktop',
  MOBILE: 'mobile'
});

export function isMobileDevice() {
  return /Android|webOS|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

export function isMobile() {
  const currentRenderMode = localStorage.getItem('renderMode');
  return isMobileDevice() && currentRenderMode !== RenderModeEnum.DESKTOP;
}
