import { INovaEntity } from './base';

export interface IMessageThread extends INovaEntity {
  orgId: string;
  assetVisitId: string;
  messages?: IMessageThreadMessage[];
  expiresAt: Date;
}

export enum IMessageStatus {
  DELIVERED = 'delivered',
  FAILED = 'failed',
  SENT = 'sent',
  RECEIVED = 'received'
}

export enum AllowedMessageThreadMediaMimeTypes {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  HEIC = 'image/heic',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  CSV = 'text/csv',
  TSV = 'text/tab-separated-values'
}

export enum MessageSourceType {
  USER = 'user',
  SYSTEM = 'system'
}

export interface IMessageThreadMessage {
  providerMessageId: string;
  orgId?: string;
  messageThreadId: string;
  timestamp?: Date;
  content: string;
  mediaUrls?: string[];
  toNumber: string;
  fromNumber: string;
  status: IMessageStatus;
  createdBy?: string;
  sourceType?: MessageSourceType;
}

export function isMessageThreadExpired(messageThread: IMessageThread): boolean {
  return new Date() > messageThread.expiresAt;
}

export const SystemMessages = {
  ERROR: {
    DEFAULT: () =>
      'Automated Response: An error occurred while processing your message. Please try again later.',
    INVALID_FILE_TYPE: (fileType?: string) =>
      `Automated Response: Sorry, your message was not delivered. ${
        fileType
          ? `The file type ${fileType} is not supported.`
          : 'This file type is not supported.'
      }`,
    THREAD_NOT_FOUND: () =>
      'Automated Response: Sorry, your message was not delivered. There are no active conversations with this warehouse.'
  }
};
